<template>
  <div>
    <!--Start info-component-->
    <info-component />
    <!--End info-component-->
  </div>
</template>

<script>
//import components
import InfoComponent from "@/components/Info/InfoComponent.vue";

export default {
  name: "Info",

  components: { InfoComponent },
};
</script>
