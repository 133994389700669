<template>
  <div>
    <v-container grid-list-xsm class="mx-auto">
      <v-card
        elevation="0"
        tile
        class="mb-5 mx-auto"
      >
        <!--start head content-->
        <v-row  class="mt-16">
          <!--start delivery times-->
          <v-col cols="12" align="center">
            <div style="max-width:250px;max-height: 250px; ">
              <v-img class="logo_style mx-2" :src="images && imageDim(images.logo,'logo')" width="100%" height="100%" contain max-width="250" max-height="250" />
            </div>
          </v-col>
        </v-row>

        <v-card-title class="text-center justify-center">
          <h2 class="font-weight-bold" v-text="shop && shop.name"></h2>
        </v-card-title>

        <v-card-text v-html="shop && shop.description">
        </v-card-text>
        <!--end head content-->

        <v-row fill-height class="align-center">
          <!--start location-->
          <v-col cols="12" class="align-center">
            <div class="align-center mt-16">
              <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true" data-projection="EPSG:4326" style="height: 300px;width:100%;">
                <vl-view :zoom.sync="zoom" :center.sync="coordinates" :rotation.sync="rotation"/>
                <vl-layer-tile id="osm">
                  <vl-source-osm/>
                </vl-layer-tile>
                <vl-layer-vector>
                  <vl-feature>
                    <vl-geom-point :coordinates="coordinates"/>
                    <vl-style-box>
                      <vl-style-circle :radius="6">
                        <vl-style-fill color="red"/>
                        <vl-style-stroke color="red"/>
                      </vl-style-circle>
                    </vl-style-box>
                  </vl-feature>
                </vl-layer-vector>
              </vl-map>
            </div>
          </v-col >
          <!--end location-->
          <!--start delivery times-->
          <v-col cols="12" md="6" lg="6" xl="6">
            <v-card-title primary-title>
              <h6>{{$t('delivery.lang_deliveryTimes')}}</h6>
            </v-card-title>

            <v-sheet color="#F8F5F2" class="pt-2 px-2">
              <v-card-text>
                <div
                  v-for="(openTm, index) in openTimes"
                  :key="index"
                  class="d-flex"
                >
                  <p v-text="days[index]"></p>
                  <v-spacer></v-spacer>
                  <p v-text="openTime(openTm)"></p>
                </div>
              </v-card-text>
            </v-sheet>
          </v-col>
          <!--end delivery times-->

          <v-col cols="12" md="6" lg="6" xl="6">
            <!--start website-->
            <div class="mt-16">
              <v-card-title primary-title>
                <h6>{{$t('delivery.lang_website')}}</h6>
              </v-card-title>

              <v-sheet color="#F8F5F2">
                <v-card-text class="text-start">
                  <a
                      class="accent--text"
                      :href="''" @click.prevent="openExternalLink(shop && shop.webSite)"
                  >
                    {{ shop && shop.webSite }}
                  </a>
                </v-card-text>
              </v-sheet>
            </div>
            <!--end website-->

            <!--start impressum-->
            <div v-if="dynamicContent">
              <v-card-title primary-title>
                <h6>{{$t('delivery.lang_imprint')}}</h6>
              </v-card-title>

              <v-sheet color="#F8F5F2">
                <v-card-text class="px-1" v-html="dynamicContent && dynamicContent.imprints">

                </v-card-text>
              </v-sheet>
            </div>
            <!--end impressum-->
          </v-col>

          <v-col cols="12" md="12" lg="12">

            <!--start deliveryCost and minOrderValue-->
            <v-card-text >
              <v-simple-table class="border" >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left" style="max-width: 50px;">{{$t('delivery.lang_zipCode')}}</th>
                    <th class="text-left" style="max-width: 50px;">{{$t('delivery.lang_minimumOrder')}}</th>
                    <th class="text-left" style="max-width: 50px;">{{$t('delivery.lang_deliveryCosts')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(area, index) in deliveryAreas" :key="index">
                    <td class="text-left" style="max-width: 50px;">{{ area.zip }}</td>
                    <td class="text-left" style="max-width: 50px;">{{ area.minimumOrderValue | currency }}</td>
                    <td class="text-left" style="max-width: 50px;">{{ area.deliveryCosts | currency }}</td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <!--end deliveryCost and minOrderValue-->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
//import plugins
import { createNamespacedHelpers } from "vuex";

export default {
  name: "InfoComponent",

  data() {
    return {
      zoom: 16,
      rotation: 0,
    };
  },

  computed: {
    ...createNamespacedHelpers("Config").mapGetters([
      "images",
      "openingTimes",
      "shop",
      "minimumOrderValue",
      "deliveryAreas",
      "dynamicContent",
    ]),

    openTimes: {
      get: function() {
        if (
          (Array.isArray(this.openingTimes) &&
            this.openingTimes.length !== 0) ||
          this.openingTimes !== null
        ) {
          return this.openingTimes;
        } else {
          return [];
        }
      },
    },
    coordinates:{
      get(){
        if(this.shop && this.shop.coordinates && Object.keys(this.shop.coordinates).length>0){
          return [Number(this.shop.coordinates.longitude),Number(this.shop.coordinates.latitude)]
        }

        return [0,0]
      },
      set(val){
      }
    },
    days() {
      return [
        this.$t("delivery.lang_monday"),
        this.$t("delivery.lang_tuesday"),
        this.$t("delivery.lang_wednesday"),
        this.$t("delivery.lang_thurday"),
        this.$t("delivery.lang_friday"),
        this.$t("delivery.lang_saturday"),
        this.$t("delivery.lang_sunday"),
      ]
    },
  },

  methods: {
    openTime: function(openTime) {
      var openT = "";

      if (!openTime.shift1.open && !openTime.shift2.open) {
        openT = this.$t('delivery.lang_closed');
      } else if (!openTime.shift1.open) {
        openT =
          openTime.shift2.start.substring(0, 5) +
          " - " +
          openTime.shift2.end.substring(0, 5);
      } else if (!openTime.shift2.open) {
        openT =
          openTime.shift1.start.substring(0, 5) +
          " - " +
          openTime.shift1.end.substring(0, 5);
      } else {
        openT =
          openTime.shift1.start.substring(0, 5) +
          " - " +
          openTime.shift2.end.substring(0, 5);
      }

      return openT;
    },
    imageDim(logo,type){
      if(!logo)
        return '';
      let baseUrl = "https://img.3pos.de/unsafe/";
      let url = logo.substring(baseUrl.length);
      let filePath = url.substring(url.split('/')[0].length)

      if(type==='logo'){
        return baseUrl+'0x350/filters:quality(65)'+filePath;
      }else{
        return baseUrl+'0x1920/filters:quality(65)'+filePath;
      }
    },
    openExternalLink(domain) {
      let a = document.createElement('a');
      if(domain && domain.includes('http')){
        a.href = domain;
      }else{
        a.href = 'https://' + domain;
      }
      a.target = '_blank';
      a.click();

      a.remove();
    },
  },
};
</script>

<style scoped>

.logo_style{
  background-color: #fff;
  border-radius: 25px;
}
</style>
